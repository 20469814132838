import { getFetcher } from '@naturalcycles/js-lib'
import { ClientId } from '@naturalcycles/shared'
import { botDetectionService, isE2eTest } from '@src/helpers/env'
import { mixpanelService } from '@src/srv/mixpanel.service'

const now = new Date()
const utcOffset = String(-now.getTimezoneOffset())
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
const botReason = botDetectionService.getBotReason()
const br = botReason ? String(botReason) : undefined
const cdp = compactBoolean(botDetectionService.isCDP())
const isMetaLandingPage = process.env['GATSBY_META_LANDING_PAGE']
const clientId = isMetaLandingPage ? ClientId.NCWebMeta : ClientId.NCWeb

class ApiService {
  getHeaders(): ApiHeaders {
    return {
      clientId,
      distinctId: mixpanelService.getDistinctId(),
      e2e: compactBoolean(isE2eTest()),
      br,
      cdp,
      utcOffset,
      tz,
    }
  }
}

export const apiService = new ApiService()

export const apiFetcher = getFetcher({
  baseUrl: process.env['GATSBY_NCBACKEND3_URL'],
}).onBeforeRequest(req => {
  Object.assign(req.init.headers, apiService.getHeaders())
})

export type CompactBoolean = '1' | undefined

export function compactBoolean(v: any): CompactBoolean {
  return v ? '1' : undefined
}

interface ApiHeaders {
  clientId: ClientId.NCWeb | ClientId.NCWebMeta
  distinctId: string | undefined
  e2e: CompactBoolean
  br: string | undefined
  cdp: CompactBoolean
  utcOffset: string
  tz: string
}
