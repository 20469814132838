import { _stringEnumValues } from '@naturalcycles/js-lib'
import { SupportedUrl } from './translations.cnst'

const languages = ['en-us', 'es', 'pt-br', 'sv']

export const domainAliases = [
  'naturalcycles.biz',
  'naturalcycles.de',
  'naturalcycles.dk',
  'naturalcycles.es',
  'naturalcycles.fr',
  'naturalcycles.info',
  'naturalcycles.net',
  'naturalcycles.com.br',
]

export const shopDomainAliases = ['shop.naturalcycles.com', 'shop.naturalcycles.se']

// shop eu redirects
export const euCountries = [
  'ad',
  'at',
  'ax',
  'be',
  'cz',
  'ch',
  'cy',
  'de',
  'dk',
  'ee',
  'es',
  'fi',
  'fo',
  'fr',
  'gf',
  'gr',
  'ie',
  'is',
  'it',
  'li',
  'lt',
  'lu',
  'lv',
  'mc',
  'mt',
  'nl',
  'no',
  'pl',
  'pt',
  'rs',
  'si',
  'sk',
  'sm',
  'ua',
  'va',
]

export const removedQuizSlugs = [
  'quiz',
  'plan-quiz',
  'your-birth-control-quiz',
  'your-plan-pregnancy-quiz',
]

export const usOnlyPages = [
  'research-library',
  'announcements',
  'other/legal',
  'press-hub',
  'c',
  'samsung',
  'press-highlights',
]

/**
 * Client-side redirects, ordered by priority
 */
export const clientSideRedirects: ClientSideRedirect[] = [
  // Some pages are only available in English
  ...usOnlyPages.map(page => ({
    from: new RegExp(`^https://(?:www\\.)?naturalcycles\\.se/${page}(/.*)?$`),
    to: `https://www.naturalcycles.com/${page}$1`,
  })),
  // .se/shop/... -> .com/shop/... (we don't localize shop item pages)
  {
    from: /^https:\/\/(?:www\.)?naturalcycles\.se\/shop\/(.+)/,
    to: 'https://www.naturalcycles.com/shop/$1',
  },
  // .se/shop -> .com/shop/se
  {
    from: /^https:\/\/(?:www\.)?naturalcycles\.se\/shop/,
    to: 'https://www.naturalcycles.com/shop/se',
  },
  // .se users to .com/sv
  {
    from: /^https:\/\/(?:www\.)?naturalcycles\.se(\/.*)?/,
    to: 'https://www.naturalcycles.com/sv$1',
  },
]

const appUrl = process.env['APP_URL']!
const signupUrl = process.env['GATSBY_SIGNUP_URL']!

if (!appUrl || !signupUrl) {
  // We can't import nodejs-lib or we get `can't access 'o' before initialization`
  throw new Error('APP_URL and GATSBY_SIGNUP_URL must be set')
}

/**
 * A list of languagePrefixes. En-us is just an empty string, but the rest are /ca /es etc.
 */
const languagesPrefixes = _stringEnumValues(SupportedUrl).map(url => (!url ? '' : `/${url}`))

const veritoneCodes = [
  'girlontop',
  'foodheals',
  'girls',
  'mintarrow',
  'nakedbeauty',
  'marriage',
  'glowgirl',
  'minimalish',
]

export const firebaseRedirects: FirebaseRedirect[] = [
  // Redirect parked anti-squat naturalcycles.* variants onto the main domain
  ...domainAliases.flatMap(domain => [
    permanent(`https://${domain}/:splat*`, 'https://www.naturalcycles.com/:splat'),
    permanent(`https://www.${domain}/:splat*`, 'https://www.naturalcycles.com/:splat'),
  ]),
  // Redirect legacy shops
  ...shopDomainAliases.flatMap(domain => [
    permanent(`https://${domain}/:splat*`, 'https://www.naturalcycles.com/shop/:splat'),
    permanent(`https://www.${domain}/:splat*`, 'https://www.naturalcycles.com/shop/:splat'),
  ]),

  // Redirect legacy traffic to the old Cyclerpedia domain to the new one
  permanent(`https://ask.naturalcycles.com{,/**}`, 'https://help.naturalcycles.com'),

  // Send legacy blog subdomain traffic to corresponding Cyclematters posts
  //  (we used to have a separate blog site on its own domain but we
  //   discontinued it in favour of Cyclematters on the main site)
  permanent(
    'https://blog.naturalcycles.com/5-surprising-facts-about-the-female-egg-cell',
    'https://www.naturalcycles.com/cyclematters/5-facts-about-the-female-egg-cell',
  ),
  permanent(
    'https://blog.naturalcycles.com/alcohol-and-your-temperature',
    'https://www.naturalcycles.com/cyclematters/ovulation-and-temperature-tracking-fertility',
  ),
  permanent(
    'https://blog.naturalcycles.com/anovulatory-cycles',
    'https://www.naturalcycles.com/cyclematters/anovulatory-cycles-explained',
  ),
  permanent(
    'https://blog.naturalcycles.com/how-to-do-a-breast-self-exam',
    'https://www.naturalcycles.com/cyclematters/how-to-do-a-self-breast-exam',
  ),
  permanent(
    'https://blog.naturalcycles.com/its-getting-hot-in-here-why-does-my-temperature-rise-after-ovulation',
    'https://www.naturalcycles.com/cyclematters/ovulation-and-temperature-tracking-fertility',
  ),
  permanent(
    'https://blog.naturalcycles.com/lets-talk-about-sperm-survival',
    'https://www.naturalcycles.com/cyclematters/the-truth-about-sperm-survival-how-long-do-sperm-live',
  ),
  permanent(
    'https://blog.naturalcycles.com/making-sense-of-cervical-fluid',
    'https://www.naturalcycles.com/cyclematters/what-is-cervical-mucus',
  ),
  permanent(
    'https://blog.naturalcycles.com/tag/basal-body-temperature',
    'https://www.naturalcycles.com/cyclematters/bbt-explained-what-is-basal-body-temperature',
  ),
  permanent(
    'https://blog.naturalcycles.com/tag/ovulation',
    'https://www.naturalcycles.com/cyclematters/ovulation-and-temperature-tracking-fertility',
  ),
  permanent(
    'https://blog.naturalcycles.com/the-fertile-window',
    'https://www.naturalcycles.com/cyclematters/what-is-the-fertile-window',
  ),
  permanent(
    'https://blog.naturalcycles.com/what-is-pelvic-floor-training-and-should-you-be-doing-it',
    'https://www.naturalcycles.com/cyclematters/what-is-pelvic-floor-training-and-should-you-be-doing-it',
  ),
  permanent(
    'https://blog.naturalcycles.com/when-will-i-ovulate',
    'https://www.naturalcycles.com/cyclematters/what-is-ovulation-and-when-will-i-ovulate',
  ),
  permanent(
    'https://blog.naturalcycles.com/winter-is-here-will-it-affect-my-basal-body-temperature',
    'https://www.naturalcycles.com/cyclematters/bbt-explained-what-is-basal-body-temperature',
  ),
  permanent('https://blog.naturalcycles.com/*', 'https://www.naturalcycles.com/cyclematters'),

  // Example page containing inbound link matching this pattern:
  // https://github.com/NaturalCycles/WebSignup/blob/6c76c88/src/environments/environment.production.ts#L13
  //
  // > appUrl = 'https://www.naturalcycles.com/app'
  //
  permanent('/app{,/**}', appUrl),

  // Example page containing inbound link matching this pattern:
  // http://blog.naturalcycles.com/natural-cycles-graph-update/ [http://archive.is/Rrp43]
  //
  // > If you cannot wait, note that you can always access Natural Cycles through
  // > our web-app to get the latest version: https://www.naturalcycles.com/en/app
  //
  ...[...languages, 'en'].map(language =>
    permanent(`/${language}/app{,/**}`, `${appUrl}/?lang=${appLanguageCode(language)}`),
  ),

  ...[...languages, 'en'].map(language =>
    permanent(`/${language}/login`, `${appUrl}/?lang=${appLanguageCode(language)}&webLogin=1`),
  ),

  ...languages.map(language =>
    permanent(`/${language}/other/privacy`, `/${language}/other/legal#privacy`),
  ),

  ...languages.map(language =>
    permanent(`/${language}/other/terms`, `/${language}/other/legal#terms`),
  ),

  permanent(
    '/ifu/',
    'https://help.naturalcycles.com/hc/en-us/articles/360003302953-Instructions-for-use',
  ),
  permanent('/different', 'https://www.naturalcycles.com?utm_source=transit'),
  permanent('/hormonefree', 'https://www.naturalcycles.com?utm_source=wild_posting'),
  permanent('/hormone-free', 'https://www.naturalcycles.com?utm_source=wild_posting'),

  // HCP redirects for not supported languages
  permanent('/de/hcp{,/**}', '/hcp'),
  permanent('/en-us/hcp{,/**}', '/hcp'),
  permanent('/pt-br/hcp{,/**}', '/hcp'),

  // Redirect the old HCP contact form to Zendesk
  permanent(
    '/en/hcp/contact-us',
    'https://help.naturalcycles.com/hc/en/requests/new?ticket_form_id=360000077038',
  ),
  permanent(
    '/es/hcp/ponte-en-contacto-con-nosotros',
    'https://help.naturalcycles.com/hc/es/requests/new?ticket_form_id=360000077038',
  ),

  // Redirects for UK science page
  permanent('/en/science{,/**}', '/the-science'),

  permanent('/birthcontrol', '/reviews'),
  permanent('/birthcontrol/stories', '/reviews'),
  permanent('/other', '/other/legal'),
  permanent('/en/other', '/en/other/legal'),
  permanent('/es/other', '/es/other/legal'),
  permanent('/pt-br/other', '/pt-br/other/legal'),
  permanent('/sv/other', '/sv/other/legal'),
  permanent('/pt-br/contraception', '/pt-br/how-does-natural-cycles-work'),
  permanent('/sv/contraception', '/sv/how-does-natural-cycles-work'),

  // DEV-5071: Remove German from the website
  permanent('/de', '/'),
  permanent('/de/about', '/about'),
  permanent('/de/app-legal/:splat*', '/en-us/app-legal/:splat'),
  permanent('/de/company-info', '/company-info'),
  permanent('/de/contact', '/'),
  permanent('/de/contraception', '/how-does-natural-cycles-work'),
  permanent('/de/contraception/how-does-natural-cycles-work', '/how-does-natural-cycles-work'),
  permanent('/de/contraception/is-natural-cycles-for-me', '/is-natural-cycles-right-for-me'),
  permanent('/de/contraception/stories', '/reviews'),
  permanent('/de/contraception/the-natural-birth-control-app', '/how-does-natural-cycles-work'),
  permanent('/de/contraception/theapp', '/birthcontrol/theapp'),
  permanent('/de/contraception/howitworks', '/how-does-natural-cycles-work'),
  permanent('/de/how', '/how-does-natural-cycles-work'),
  permanent('/de/faqs', '/faqs'),
  permanent('/de/news/:splat*', '/news/:splat'),
  permanent('/de/news', '/news'),
  permanent('/de/other/:splat*', '/other/:splat'),
  permanent('/de/plan-pregnancy', '/plan-pregnancy'),
  permanent('/de/science{,/**}', '/the-science'),
  permanent('/de/terms', '/other/legal'),

  // Redirect old HCP URLs to current en-US HCP
  permanent('/en/hcp{,/**}', '/hcp'),
  permanent('/es/hcp{,/**}', '/hcp'),

  permanent('/assets/legal/privacypolicy.html', '/other/legal#privacy'),

  // Birth Control page redirects
  permanent(
    '/birthcontrol/the-natural-birth-control-app',
    '/cyclematters/non-hormonal-birth-control-methods',
  ),
  permanent(
    '/en/contraception/the-natural-birth-control-app',
    '/cyclematters/non-hormonal-birth-control-methods',
  ),

  // Redirects for the .com/en-us -> .com move
  permanent('/en-us/birthcontrol/guides', '/is-natural-cycles-right-for-me'),
  permanent('/en-us/birthcontrol/*', '/how-does-natural-cycles-work'),
  permanent('/en-us/science/*', '/the-science'),
  permanent('/en-us/other/:splat*', '/other/:splat'),
  permanent('/en-us/news/:splat*', '/news/:splat'),
  permanent('/en-us/press-contact', '/press-contact'),
  permanent('/en-us/company-info', '/company-info'),
  permanent('/en-us/instructions-for-use', 'https://help.naturalcycles.com'),
  permanent('/en-us/plan-pregnancy', '/plan-pregnancy'),
  permanent('/en-us/about', '/about'),
  permanent('/en-us/faqs', '/faqs'),
  permanent('/en-us', '/'),

  // Redirecting old science pages to the new one
  permanent('/science/certifications', '/the-science'),
  permanent('/science/research', '/the-science'),
  permanent('/science/clinical-studies', '/the-science'),
  permanent('/science', '/the-science'),

  // DEV-4621: Redirect old UK URLs to their US counterparts
  permanent('/en/about', '/about'),
  permanent('/en/company-info', '/company-info'),
  permanent('/en/contraception/stories/', '/reviews'),
  permanent('/en/contraception/*', '/how-does-natural-cycles-work'),
  permanent('/en/how', '/how-does-natural-cycles-work'),
  permanent('/en/faqs', '/faqs'),
  permanent('/en/is-it-right-for-me/', '/is-natural-cycles-right-for-me'),
  permanent('/en/news/:splat*', '/news/:splat'),
  permanent('/en/other/legal', '/other/legal'),
  permanent('/en/plan-pregnancy', '/plan-pregnancy'),
  permanent('/en/press-contact', '/press-contact'),
  permanent('/en/the-science', '/the-science'),
  permanent('/en', '/'),

  // DEV-5173: Retire app features page
  permanent('/birthcontrol/theapp', '/'),

  // Redirect the old contact page to Cyclerpedia
  permanent('/contact', 'https://help.naturalcycles.com/hc/en-us/requests/new'),
  permanent('/en-us/contact', 'https://help.naturalcycles.com/hc/en-us/requests/new'),
  permanent('/pt-br/contact', 'https://help.naturalcycles.com/hc/en-us/requests/new'),

  // Redirect a concise url to Cyclerpedia
  permanent(
    '/submitrequest',
    'https://help.naturalcycles.com/hc/en-us/requests/new?data-privacy=true',
  ),

  permanent('/es/contraception', '/es'),
  permanent('/es/contraception/stories', '/es/reviews'),
  permanent('/es/contraception/theapp', '/es/how-does-natural-cycles-work'),
  permanent('/es/science{,/**}', '/es/the-science'),

  permanent('/pt-br/contraception/stories', '/pt-br/reviews'),
  permanent('/pt-br/science{,/**}', '/pt-br/the-science'),

  permanent('/sv/contraception/stories', '/sv/reviews'),
  permanent('/sv/science{,/**}', '/sv/the-science'),
  permanent('/sv/plan', '/sv/plan-pregnancy'),

  // Redirect Finnish urls
  permanent('/fi{,/**}', '/'),

  // There used to a plain /pt/ language directory on the site but it's long
  // gone and needs all its traffic sending to /pt-br/
  permanent('/pt/:splat*', '/pt-br/:splat'),

  // WebSignup redirects
  found('/signup/secret', `${signupUrl}/en-US/secret`),
  permanent('/_:splat*', `${signupUrl}/en-US/secret?code=:splat`),
  permanent('/code', `${signupUrl}/en-US/secret`),
  permanent('/signup', signupUrl),
  permanent('/signup/:splat*', `${signupUrl}/:splat`),
  permanent('/:lang*/signup', `${signupUrl}/:lang`),
  permanent('/:lang*/signup/:splat*', `${signupUrl}/:lang/:splat`),

  permanent('/mama', `${signupUrl}/en-US/secret?code=mama`),
  permanent('/20GoNatural', `${signupUrl}/en-US/secret?code=20GoNatural&ignoreConfirmation=true`),
  permanent('/20gonatural', `${signupUrl}/en-US/secret?code=20GoNatural&ignoreConfirmation=true`),
  permanent('/GoNatural20', `${signupUrl}/en-US/secret?code=GoNatural20&ignoreConfirmation=true`),
  permanent('/gonatural20', `${signupUrl}/en-US/secret?code=GoNatural20&ignoreConfirmation=true`),
  permanent('/betches', `${signupUrl}/en-US/secret?code=betches`),

  permanent('/is-natural-cycles-for-me', '/is-natural-cycles-right-for-me'),
  permanent('/birthcontrol/is-natural-cycles-for-me', '/is-natural-cycles-right-for-me'),
  permanent('/sv/contraception/is-natural-cycles-for-me', '/sv/is-natural-cycles-right-for-me'),
  permanent('/es/contraception/is-natural-cycles-for-me', '/es/is-natural-cycles-right-for-me'),
  permanent(
    '/pt-br/contraception/is-natural-cycles-for-me',
    '/pt-br/is-natural-cycles-right-for-me',
  ),

  permanent('/birthcontrol/how-does-natural-cycles-work', '/how-does-natural-cycles-work'),
  permanent('/sv/contraception/how-does-natural-cycles-work', '/sv/how-does-natural-cycles-work'),
  permanent('/es/contraception/how-does-it-work', '/es/how-does-natural-cycles-work'),
  permanent(
    '/pt-br/contraception/how-does-natural-cycles-work',
    '/pt-br/how-does-natural-cycles-work',
  ),

  permanent('/toolkits/*', '/'),

  permanent('/hcp/counseling-your-patients', '/hcp'),
  permanent('/hcp/publications-and-clinical-findings', '/publications'),
  permanent('/research', '/publications'),
  permanent('/sv/research', '/sv/publications'),
  permanent('/hcp/what-is-natural-cycles', '/hcp'),

  permanent('/c/springessentials', '/'),

  permanent('/c/period-tracking', '/cyclematters/what-is-the-menstrual-cycle'),

  permanent('/daddy', '/c/call-her-daddy'),

  permanent('/applewatch', '/apple-watch'),

  permanent(
    '/fertility-algorithm-explanation',
    'https://www.datocms-assets.com/21281/1623745372-algo-description-for-external-use.pdf',
  ),

  // after Oura launch removed wearables page and redirected to Oura
  permanent('/wearables', 'https://www.naturalcycles.com/oura'),

  // Redirects from the old /news/ slugs to new /announcement/ slugs
  permanent('/news', '/press-hub'),
  permanent('/news/:slug', '/announcements/:slug'),
  permanent('/recent-press', '/press-hub'),
  permanent('/company-info', '/press-hub'),

  // HW page - Note: the order of the following lines is important! from more specific to less specific

  found('/devices/us', '/devices/eu'),
  ...languagesPrefixes.flatMap(prefix =>
    // The ncweb quiz has been removed, but links might still exist in dato / the wild
    removedQuizSlugs.map(slug => permanent(`${prefix}/${slug}`, signupUrl)),
  ),

  found('/store', '/shop'),

  found('/c/qrcode_onboarding', '/'),

  ...veritoneCodes.map(veritoneCode =>
    permanent(
      `/${veritoneCode}`,
      `${signupUrl}/en-US/secret?${[
        ['code', veritoneCode].join('='),
        ['utm_campaign', 'veritone-podcast'].join('='),
        ['utm_medium', 'veritone'].join('='),
        ['utm_content', veritoneCode].join('='),
        ['utm_term', veritoneCode].join('='),
        ['utm_source', 'podcast'].join('='),
      ].join('&')}`,
    ),
  ),

  // Influencer redirect
  permanent(
    '/emily',
    '/join-me?utm_campaign=instagram-all&utm_medium=organic&utm_content=emily-karlsson&utm_source=instagram&code=Emily&name=Emily&percentage=20',
  ),
  permanent(
    '/sanne',
    '/sv/join-me?utm_campaign=instagram-all&utm_medium=organic&utm_content=sanne-josefson&utm_source=instagram&code=SanneJ&name=Sanne&percentage=20',
  ),
  permanent(
    '/montana',
    '/join-me?utm_campaign=instagram-all&utm_medium=organic&utm_content=montana-brown&utm_source=instagram&code=Montana20&name=Montana&percentage=20',
  ),
  permanent(
    '/tinx',
    '/join-me?utm_campaign=instagram-all&utm_medium=organic&utm_content=tinx&utm_source=instagram&code=Tinx&name=Tinx&percentage=20',
  ),
  permanent(
    '/surret',
    '/sv/join-me-plan?utm_campaign=podcast&utm_medium=organic&utm_content=surret&utm_source=podcast&code=Surret&name=Surret&percentage=20',
  ),
  permanent(
    '/cleo',
    '/join-me?utm_campaign=tiktok&utm_medium=organic&utm_content=cleo-abram&utm_source=instagram&code=Cleo&name=Cleo&percentage=20',
  ),
  permanent(
    '/LiveWell',
    '/join-me?utm_campaign=podcast&utm_medium=organic&utm_content=live-well-be-well&utm_source=instagram&code=LiveWell&name=LiveWellBeWell&percentage=20',
  ),
  permanent(
    '/livewell',
    '/join-me?utm_campaign=podcast&utm_medium=organic&utm_content=live-well-be-well&utm_source=instagram&code=LiveWell&name=LiveWellBeWell&percentage=20',
  ),
  permanent(
    '/systrarnakjos',
    '/sv/join-me?utm_campaign=podcast&utm_medium=organic&utm_content=systrarna-kjos&utm_source=instagram&code=Systrarnakjos&name=SystrarnaKjos&percentage=20',
  ),
  permanent(
    '/alltsom',
    '/sv/join-me?utm_campaign=podcast&utm_medium=organic&utm_content=allt-som&utm_source=instagram&code=Alltsom&name=AlltSom%C3%84r&percentage=20',
  ),
  permanent(
    '/keepingup',
    '/sv/join-me?utm_campaign=podcast&utm_medium=organic&utm_content=keeping-up&utm_source=instagram&code=Keepingup&name=KeepingUp&percentage=20',
  ),
  permanent(
    '/dani',
    'https://www.naturalcycles.com/join-me?utm_campaign=instagram-story&utm_medium=organic&utm_content=dani-austin&utm_source=instagram&code=Dani&name=Dani&percentage=20',
  ),
  permanent(
    '/Sarah',
    '/join-me?utm_campaign=instagram-all&utm_medium=organic&utm_content=sarah-hyland&utm_source=Instagram&code=Sarah&name=Sarah&percentage=20',
  ),
  permanent(
    '/sarah',
    '/join-me?utm_campaign=instagram-all&utm_medium=organic&utm_content=sarah-hyland&utm_source=Instagram&code=Sarah&name=Sarah&percentage=20',
  ),
  permanent('/dst', '/c/dst'),
  permanent('/DST', '/c/dst'),

  permanent('/downloaddata', '/downloadData'),

  // Alethia asked
  permanent('/download', '/'),
]

export const firebaseI18nRewrites: FirebaseLocalizedRewrite[] = [
  ...languagesPrefixes.flatMap(
    prefix =>
      // for now US and Canada will also see T2 on the EU page
      ['us', 'se', 'ca', ...euCountries].map(country =>
        countryRewrite(`${prefix}/devices`, `${prefix}/devices/eu`, country),
      ),

    // TODO: redirect to US page one we start shipping T3s again
    // TODO[2025-02-12] revisit this comment ^ to see if it's still relevant

    // countryRewrite(`${prefix}/devices`, `${prefix}/devices/us`, 'us')

    // We want 404 for /devices on non-supported countries
    // found(`${prefix}/devices`, `/`)
  ),

  // This line looks weird (from / to / ??),
  // but it's needed, otherwise Browser with Languages `en,sv` will be served Swedish,
  // even though it's listed on the second place.
  // This is because English doesn't have it's place in `localized` folder.
  // So, this weird line fixes it by serving /index.html for `en` language
  languageRewrite('/', '/', 'en'),

  languageRewrite('/', '/sv', 'sv'),
  languageRewrite('/', '/pt-br', 'pt'),
  languageRewrite('/', '/es', 'es'),
  languageRewrite('/', '/ca', 'ca'),

  // Shop redirects
  countryRewrite('/shop', '/shop/uk', 'gb'),
  countryRewrite('/shop', '/shop/se', 'se'),
  countryRewrite('/shop', '/shop/us', 'us'),

  ...euCountries.map(country => countryRewrite('/shop', '/shop/eu', country)),
]

export const firebaseRewrites: FirebaseRewrite[] = [rewrite('/shop', '/shop/row/index.html')]

export interface FirebaseRedirect {
  source: string
  destination: string
  type: number
}

export interface FirebaseRewrite {
  source: string
  destination: string
}

export interface FirebaseLocalizedRewrite {
  source: string
  destination: string
  country: string
  language: string
}

interface ClientSideRedirect {
  from: RegExp
  /**
   * Can include regex groups from the `from` regex
   */
  to: string
}

function permanent(source: string, destination: string): FirebaseRedirect {
  return {
    source,
    destination,
    type: 301,
  }
}

function found(source: string, destination: string): FirebaseRedirect {
  return {
    source,
    destination,
    type: 302,
  }
}

function rewrite(source: string, destination: string): FirebaseRewrite {
  return {
    source,
    destination,
  }
}

function countryRewrite(
  source: string,
  destination: string,
  country = 'ALL',
): FirebaseLocalizedRewrite {
  return {
    source,
    destination,
    country,
    language: 'ALL',
  }
}

function languageRewrite(
  source: string,
  destination: string,
  language = 'ALL',
): FirebaseLocalizedRewrite {
  return {
    source,
    destination,
    country: 'ALL',
    language,
  }
}

function appLanguageCode(webLanguageCode: string): string {
  switch (webLanguageCode) {
    case 'en-us': {
      return 'en'
    }

    // confirmed by trial and error
    case 'pt-br': {
      return 'pt'
    }

    // https://nc1.slack.com/archives/C848XEU21/p1549619915021100
    // https://nc1.slack.com/archives/C04QAATAS/p1575374094001000
    case 'en': {
      return 'en-GB'
    }

    default: {
      return webLanguageCode
    }
  }
}
