import { useAppSelector } from '@src/hooks/useAppSelector'
import { ShippingCurrency, ShippingLocation } from '@src/shop/cnst/shopify.cnst'
import { selectShopifyCurrency, selectShopifyShippingLocation } from '@src/shop/store/shopify.slice'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import styles from './BannerShop.module.scss'

interface BannerShopProps {}

export function BannerShop({}: BannerShopProps): ReactNode {
  const currentCurrency = useAppSelector(selectShopifyCurrency)
  const shippingLocation = useAppSelector(selectShopifyShippingLocation)
  const [currencyCode, setCurrencyCode] = useState<ShippingCurrency>(currentCurrency)

  const currencyAmounts = {
    [ShippingCurrency.SE]: 400,
    [ShippingCurrency.EU]: 40,
    [ShippingCurrency.GB]: 30,
    [ShippingCurrency.US]: 40,
  }

  const amount = currencyAmounts[currencyCode] || 40

  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    maximumSignificantDigits: 3,
  }).format(amount)

  useEffect(() => {
    switch (shippingLocation) {
      case ShippingLocation.SE: {
        setCurrencyCode(ShippingCurrency.SE)
        break
      }
      case ShippingLocation.EU: {
        setCurrencyCode(ShippingCurrency.EU)
        break
      }
      case ShippingLocation.GB: {
        setCurrencyCode(ShippingCurrency.GB)
        break
      }
      default: {
        setCurrencyCode(ShippingCurrency.US)
        break
      }
    }
  }, [shippingLocation])

  return (
    <div className={styles.BannerShop}>
      <p className={styles.BannerShop__text}>
        {shippingLocation === ShippingLocation.US
          ? 'Limited-time: Save 20% when you buy 4 packs of Clearblue Digital Ovulation Tests'
          : `Free shipping on orders over ${formattedAmount}`}
      </p>
    </div>
  )
}
