import { useMemo } from 'react'
import { useAppSelector } from './useAppSelector'
import { PageType, usePageType } from './usePageType'

export function useCanHaveJanuaryBanner(): boolean {
  const pageType = usePageType()
  const allowedPageTypes = [PageType.StandardHome]

  const discountCode = useAppSelector(state => state.discountCode.code)
  const hasDiscountCode = !!discountCode

  return useMemo(() => {
    return allowedPageTypes.includes(pageType) && !hasDiscountCode
  }, [pageType, hasDiscountCode])
}
