import { BackendResponseFM } from '@naturalcycles/shared'
import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit'
import { getAllUtms } from '@src/helpers/queryParams'
import { ShippingCurrency, ShippingLocation } from '@src/shop/cnst/shopify.cnst'
import { userLocaleSlice } from '@src/store/userLocale.slice'
import Cookies from 'js-cookie'
import { ShopifyState, slice as shopify } from '../shop/store/shopify.slice'
import { slice as cyclemattersSearch } from './cyclemattersSearch/cyclemattersSearch.slice'
import { slice as discountCode } from './discountCode/discountCode.slice'
import { slice as experiment } from './experiment/experiment.slice'
import { GeolocationCountry, slice as geolocation } from './geolocation/geolocation.slice'
import { slice as mobileMenu } from './mobileMenu/mobileMenu.slice'
import { slice as pathname } from './pathname/pathname.slice'
import { PopupState, PopupStatuses, slice as popup } from './popup/popup.slice'
import { slice as product } from './product/product.slice'
const reducer = combineReducers({
  mobileMenu: mobileMenu.reducer,
  geolocation: geolocation.reducer,
  popup: popup.reducer,
  shopify: shopify.reducer,
  discountCode: discountCode.reducer,
  cyclemattersSearch: cyclemattersSearch.reducer,
  pathname: pathname.reducer,
  experiment: experiment.reducer,
  userLocale: userLocaleSlice.reducer,
  product: product.reducer,
})

// If the user is coming from the app or with a discount code parameter we disable all popups
const popupFreeSources = ['NCApp', 'check-websignup', 'attentive']
let popupStatus = PopupStatuses.Closed

if (typeof window !== 'undefined') {
  const searchParams = new URLSearchParams(window.location.search)
  const utms = getAllUtms()
  const hasDiscountCode = searchParams.has('code')

  if (utms['utm_source'] && (popupFreeSources.includes(utms['utm_source']) || hasDiscountCode)) {
    popupStatus = PopupStatuses.Disabled
  }
}

interface PreloadedStateProps {
  popup: PopupState
  shopify: ShopifyState
}

const preloadedState: PreloadedStateProps = {
  popup: {
    status: popupStatus,
    impressions: {},
  },
  shopify: {
    cart: {},
    currency: ShippingCurrency.ROW,
    alteredRegion: false,
    miniCartOpened: false,
  },
}

if (typeof window !== 'undefined') {
  const shippingLocation = sessionStorage.getItem('Region:')
  const expectedLocation = sessionStorage.getItem('ExpectedLocation:')
  if (shippingLocation) {
    preloadedState.shopify.shippingLocation = shippingLocation as ShippingLocation
    preloadedState.shopify.currency =
      shippingLocation === ShippingLocation.Blocked
        ? ShippingCurrency.ROW
        : ShippingCurrency[shippingLocation as Exclude<keyof typeof ShippingLocation, 'Blocked'>] ||
          ShippingCurrency.ROW
  }

  if (expectedLocation) {
    preloadedState.shopify.expectedLocation = expectedLocation as ShippingLocation
  }

  Object.entries(localStorage).forEach(([key, value]) => {
    const popupCampaign = /<Popup name="([^"]+)" \/>/.exec(key)
    if (popupCampaign) {
      ;(preloadedState.popup.impressions as any)[popupCampaign[1]!] = Number.parseInt(value, 10)
    }
  })

  const cart = sessionStorage.getItem('cart')
  if (cart) {
    preloadedState.shopify.cart = JSON.parse(cart)
  }
}

export const store = configureStore({
  reducer,
  preloadedState,
})

export function onBackendResponse(br: BackendResponseFM): Thunk {
  return dispatch => {
    if (br.experiment) {
      dispatch(experiment.actions.replaceExperiment(br.experiment))
    }

    if (br.userLocale) {
      dispatch(userLocaleSlice.actions.replace(br.userLocale))
      // Right now we're basing our "geolocation" service upon webInit `br.userLocale.country`
      // it does geolocationDone.resolve() inside
      const firebaseOverride = Cookies.get('firebase-country-override')
      if (firebaseOverride) {
        dispatch(geolocation.actions.success(firebaseOverride as GeolocationCountry))
      } else {
        dispatch(geolocation.actions.success(br.userLocale.country as GeolocationCountry))
      }
    }

    if (br.product) {
      dispatch(product.actions.replace(br.product))
    }
  }
}

export type RootState = ReturnType<typeof reducer>
export type Thunk = ThunkAction<void, RootState, unknown, Action<string>>
export type Dispatch = ThunkDispatch<RootState, unknown, Action<string>>

// eslint-disable-next-line import-x/no-default-export
export default function getStore(): typeof store {
  return store
}

if (typeof window !== 'undefined') {
  ;(window as any).getState = () => store.getState()
}
