import { SupportedLocale } from '@src/cnst/translations.cnst'
import { HeaderLayouts } from '@src/components/header/Header.component'
import { languageContext } from '@src/context/language.context'
import { PageType, ShopPageTypes } from '@src/hooks/usePageType'
import { useContext } from 'react'

export function getLayout(pageType: PageType, code: string): HeaderLayouts {
  const { locale } = useContext(languageContext)
  const showBannerInHomepageLangs = [SupportedLocale.svSE, SupportedLocale.ptBR]

  if (pageType === PageType.ShopHome) {
    return HeaderLayouts.ShopHome
  }
  if (ShopPageTypes.includes(pageType)) {
    return HeaderLayouts.Shop
  }
  if (pageType === PageType.StandardHome && code && showBannerInHomepageLangs.includes(locale)) {
    return HeaderLayouts.StandardHomeDiscountBanner
  }
  if (pageType === PageType.StandardHome) {
    return HeaderLayouts.StandardHome
  }
  if (pageType === PageType.Standard && code) {
    return HeaderLayouts.StandardDiscountBanner
  }
  return HeaderLayouts.Standard
}
