import { _errorDataAppend } from '@naturalcycles/js-lib'
import { sentry } from '@src/srv/sentry.service'

export function toURL(urlString: string): URL | undefined {
  if (!urlString) return

  if (['#', '/'].some(prefix => urlString.startsWith(prefix))) {
    urlString = location.origin + urlString
  }

  // The URL constructor requires a protocol, so we'll add one if it's missing
  if (!urlString.includes('://')) {
    urlString = 'https://' + urlString
  }
  try {
    return new URL(urlString)
  } catch (err) {
    sentry.captureException(_errorDataAppend(err, { fingerprint: 'traffic_origin_invalid_url' }))
    // If the URL is invalid, we'll just ignore it
    return
  }
}
