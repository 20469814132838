import { _objectEntries, _stringEnumKey } from '@naturalcycles/js-lib'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createStorefrontApiClient } from '@shopify/storefront-api-client'
import { isE2eTest, SHOPIFY_US_DOMAIN } from '@src/helpers/env'
import { ncNavigate } from '@src/helpers/nc-navigate'
import { getAllUtms } from '@src/helpers/queryParams'
import { regionKeys, ShippingCurrency, ShippingLocation } from '@src/shop/cnst/shopify.cnst'
import { mixpanelService } from '@src/srv/mixpanel.service'
import { Thunk } from '@src/store'
import { GeolocationCountry, slice as geolocation } from '@src/store/geolocation/geolocation.slice'
import {
  AddToCartSources,
  CreateCartData,
  CreateCartInput,
  CreateCheckoutData,
  CreateCheckoutInput,
  PersistedSellingPlan,
  ShopifyCart,
  ShopifyLineItem,
  ShopifyProduct,
} from './shopify.model'
import cartCreateMutation from './shopify-gql/cart-create.graphql'
import checkoutCreateMutation from './shopify-gql/checkout-create.graphql'

// List of countries we don't ship webshop products to
export const shippingBlockList: GeolocationCountry[] = [
  GeolocationCountry.AF,
  GeolocationCountry.AO,
  GeolocationCountry.AI,
  GeolocationCountry.AQ,
  GeolocationCountry.AG,
  GeolocationCountry.AR,
  GeolocationCountry.AW,
  GeolocationCountry.AZ,
  GeolocationCountry.BS,
  GeolocationCountry.BH,
  GeolocationCountry.BD,
  GeolocationCountry.BB,
  GeolocationCountry.BZ,
  GeolocationCountry.BJ,
  GeolocationCountry.BM,
  GeolocationCountry.BT,
  GeolocationCountry.BO,
  GeolocationCountry.BQ,
  GeolocationCountry.BW,
  GeolocationCountry.BV,
  GeolocationCountry.IO,
  GeolocationCountry.BN,
  GeolocationCountry.BF,
  GeolocationCountry.BI,
  GeolocationCountry.CM,
  GeolocationCountry.KY,
  GeolocationCountry.CF,
  GeolocationCountry.TD,
  GeolocationCountry.CL,
  GeolocationCountry.CO,
  GeolocationCountry.KM,
  GeolocationCountry.CG,
  GeolocationCountry.CR,
  GeolocationCountry.CI,
  GeolocationCountry.CU,
  GeolocationCountry.CW,
  GeolocationCountry.CD,
  GeolocationCountry.DJ,
  GeolocationCountry.DM,
  GeolocationCountry.DO,
  GeolocationCountry.EC,
  GeolocationCountry.EG,
  GeolocationCountry.SV,
  GeolocationCountry.GQ,
  GeolocationCountry.ER,
  GeolocationCountry.ET,
  GeolocationCountry.FK,
  GeolocationCountry.GF,
  GeolocationCountry.GA,
  GeolocationCountry.GM,
  GeolocationCountry.GH,
  GeolocationCountry.GD,
  GeolocationCountry.GP,
  GeolocationCountry.GT,
  GeolocationCountry.GN,
  GeolocationCountry.GW,
  GeolocationCountry.GY,
  GeolocationCountry.HT,
  GeolocationCountry.HN,
  GeolocationCountry.ID,
  GeolocationCountry.IL,
  GeolocationCountry.IR,
  GeolocationCountry.IQ,
  GeolocationCountry.JM,
  GeolocationCountry.JO,
  GeolocationCountry.KZ,
  GeolocationCountry.KE,
  GeolocationCountry.KI,
  GeolocationCountry.KW,
  GeolocationCountry.KG,
  GeolocationCountry.LS,
  GeolocationCountry.LR,
  GeolocationCountry.LY,
  GeolocationCountry.MG,
  GeolocationCountry.MW,
  GeolocationCountry.ML,
  GeolocationCountry.MH,
  GeolocationCountry.MQ,
  GeolocationCountry.MR,
  GeolocationCountry.MU,
  GeolocationCountry.YT,
  GeolocationCountry.FM,
  GeolocationCountry.MS,
  GeolocationCountry.MA,
  GeolocationCountry.MZ,
  GeolocationCountry.NA,
  GeolocationCountry.NR,
  GeolocationCountry.NI,
  GeolocationCountry.NE,
  GeolocationCountry.NG,
  GeolocationCountry.NU,
  GeolocationCountry.OM,
  GeolocationCountry.PK,
  GeolocationCountry.PW,
  GeolocationCountry.PS,
  GeolocationCountry.PA,
  GeolocationCountry.PY,
  GeolocationCountry.PE,
  GeolocationCountry.PR,
  GeolocationCountry.QA,
  GeolocationCountry.RE,
  GeolocationCountry.RW,
  GeolocationCountry.BL,
  GeolocationCountry.KN,
  GeolocationCountry.LC,
  GeolocationCountry.MF,
  GeolocationCountry.PM,
  GeolocationCountry.VC,
  GeolocationCountry.ST,
  GeolocationCountry.SA,
  GeolocationCountry.SN,
  GeolocationCountry.SL,
  GeolocationCountry.SX,
  GeolocationCountry.SO,
  GeolocationCountry.ZA,
  GeolocationCountry.SS,
  GeolocationCountry.SD,
  GeolocationCountry.SR,
  GeolocationCountry.SZ,
  GeolocationCountry.SY,
  GeolocationCountry.TJ,
  GeolocationCountry.TZ,
  GeolocationCountry.TL,
  GeolocationCountry.TG,
  GeolocationCountry.TK,
  GeolocationCountry.TO,
  GeolocationCountry.TT,
  GeolocationCountry.TN,
  GeolocationCountry.TM,
  GeolocationCountry.TC,
  GeolocationCountry.TV,
  GeolocationCountry.UG,
  GeolocationCountry.AE,
  GeolocationCountry.UY,
  GeolocationCountry.UZ,
  GeolocationCountry.VU,
  GeolocationCountry.VE,
  GeolocationCountry.VN,
  GeolocationCountry.VG,
  GeolocationCountry.VI,
  GeolocationCountry.WF,
  GeolocationCountry.EH,
  GeolocationCountry.YE,
  GeolocationCountry.ZM,
  GeolocationCountry.ZW,
]

const sekCountries: GeolocationCountry[] = [GeolocationCountry.SE]

const gbpCountries: GeolocationCountry[] = [
  GeolocationCountry.FK,
  GeolocationCountry.GB,
  GeolocationCountry.GG,
  GeolocationCountry.GI,
  GeolocationCountry.IM,
  GeolocationCountry.JE,
]

export const eurCountries: GeolocationCountry[] = [
  GeolocationCountry.AD,
  GeolocationCountry.AT,
  GeolocationCountry.AX,
  GeolocationCountry.BE,
  GeolocationCountry.CH,
  GeolocationCountry.CZ,
  GeolocationCountry.CY,
  GeolocationCountry.DE,
  GeolocationCountry.DK,
  GeolocationCountry.EE,
  GeolocationCountry.ES,
  GeolocationCountry.EU,
  GeolocationCountry.FI,
  GeolocationCountry.FO,
  GeolocationCountry.FR,
  GeolocationCountry.GF,
  GeolocationCountry.GR,
  GeolocationCountry.IE,
  GeolocationCountry.IS,
  GeolocationCountry.IT,
  GeolocationCountry.LI,
  GeolocationCountry.LT,
  GeolocationCountry.LU,
  GeolocationCountry.LV,
  GeolocationCountry.MC,
  GeolocationCountry.MT,
  GeolocationCountry.NL,
  GeolocationCountry.NO,
  GeolocationCountry.PL,
  GeolocationCountry.PT,
  GeolocationCountry.RS,
  GeolocationCountry.SI,
  GeolocationCountry.SK,
  GeolocationCountry.SM,
  GeolocationCountry.UA,
  GeolocationCountry.VA,
]

export interface ShopifyState {
  cart: ShopifyCart
  currency: ShippingCurrency
  // we allow these to be undefined until decided, and let users of it handle the undefined case
  shippingLocation?: ShippingLocation
  expectedLocation?: ShippingLocation
  alteredRegion: boolean
  miniCartOpened: boolean
}

const shopifyToken = process.env['GATSBY_SHOPIFY_US_TOKEN']!

const shopifyClient = createStorefrontApiClient({
  storeDomain: SHOPIFY_US_DOMAIN,
  publicAccessToken: shopifyToken,
  // https://shopify.dev/docs/api/usage/versioning
  apiVersion: '2024-04',
})

const initialState: ShopifyState = {
  cart: {},
  currency: ShippingCurrency.ROW,
  shippingLocation: ShippingLocation.ROW,
  alteredRegion: false,
  miniCartOpened: false,
}

// TODO: this can be simplified and made more readable and DRY
export const slice = createSlice({
  name: 'shopify',
  initialState,

  reducers: {
    setMiniCartOpened(shopify: ShopifyState, action: PayloadAction<boolean>) {
      shopify.miniCartOpened = action.payload
    },
    addToCart(shopify: ShopifyState, action: PayloadAction<ShopifyLineItem>) {
      const lineItem = action.payload
      if (shopify.cart[lineItem.variantId]) {
        shopify.cart[lineItem.variantId]!.quantity += lineItem.quantity
      } else {
        shopify.cart[lineItem.variantId] = lineItem
      }
    },

    changeLineItemQuantity(shopify: ShopifyState, action: PayloadAction<ShopifyLineItem>) {
      const lineItem = action.payload
      shopify.cart[lineItem.variantId]!.quantity = lineItem.quantity
    },

    changeIsSubscription(shopify: ShopifyState, action: PayloadAction<ShopifyLineItem>) {
      const lineItem = action.payload
      shopify.cart[lineItem.variantId]!.isSubscription = lineItem.isSubscription
    },

    changeLineItemSellingPlan(
      shopify: ShopifyState,
      action: PayloadAction<{
        variantId: string
        sellingPlanId: string | undefined
      }>,
    ) {
      const { variantId, sellingPlanId } = action.payload
      shopify.cart[variantId]!.sellingPlanId = sellingPlanId
    },

    removeFromCart(shopify: ShopifyState, action: PayloadAction<string>) {
      delete shopify.cart[action.payload]
    },

    clearCart(shopify: ShopifyState) {
      shopify.cart = {}
    },

    changeShippingLocation(shopify: ShopifyState, action: PayloadAction<ShippingLocation>) {
      shopify.alteredRegion = true
      const previousLocation = shopify.shippingLocation
      if (previousLocation === action.payload) return
      // Availability of items differ between regions, so we need to clear the cart if the region changes
      sessionStorage.removeItem('cart')
      shopify.shippingLocation = action.payload
      shopify.currency =
        action.payload === ShippingLocation.Blocked
          ? ShippingCurrency.ROW
          : ShippingCurrency[
              _stringEnumKey(ShippingLocation, action.payload) as Exclude<
                keyof typeof ShippingLocation,
                'Blocked'
              >
            ] || ShippingCurrency.ROW
      sessionStorage.setItem('Region:', action.payload)
    },

    setShippingLocationByCountry(shopify: ShopifyState, action: PayloadAction<GeolocationCountry>) {
      const country = action.payload
      const shippingLocation = getLocationFromCountry(country)
      shopify.shippingLocation = shippingLocation
      shopify.currency = getCurrencyFromCountry(country)
      shopify.alteredRegion = true
    },

    setExpectedLocationByCountry(shopify: ShopifyState, action: PayloadAction<GeolocationCountry>) {
      const location = getLocationFromCountry(action.payload)
      shopify.expectedLocation = location
      if (location) {
        sessionStorage.setItem('ExpectedLocation:', location)
      }
    },

    setExpectedLocation(
      shopify: ShopifyState,
      action: PayloadAction<ShippingLocation | undefined>,
    ) {
      shopify.expectedLocation = action.payload
      if (action.payload) {
        sessionStorage.setItem('ExpectedLocation:', action.payload)
      }
    },

    alteredRegion(shopify: ShopifyState, action: PayloadAction<boolean>) {
      shopify.alteredRegion = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(geolocation.actions.success, state => {
      if (isE2eTest()) {
        state.shippingLocation = ShippingLocation.US
        return
      }
    })
  },
})

// The currency defaults to USD on page refresh, regardless of the user's location.
// TODO: A proper refactor of the reducers and state management is needed to ensure long-term stability.
function getCurrencyFromCountry(country: GeolocationCountry): ShippingCurrency {
  if (sekCountries.includes(country)) {
    return ShippingCurrency.SE
  }
  if (gbpCountries.includes(country)) {
    return ShippingCurrency.GB
  }
  if (eurCountries.includes(country)) {
    return ShippingCurrency.EU
  }
  return ShippingCurrency.ROW
}

export function getLocationFromCountry(country: GeolocationCountry): ShippingLocation | undefined {
  let shippingLocation: ShippingLocation | undefined

  regionKeys.forEach(region => {
    if (region === country) {
      shippingLocation = ShippingLocation[region]
      return
    }
    if (region === 'EU' && eurCountries.includes(country)) {
      shippingLocation = ShippingLocation.EU
      return
    }
  })
  if (shippingBlockList.includes(country)) {
    shippingLocation = ShippingLocation.Blocked
  }

  return shippingLocation
}

export const { setMiniCartOpened } = slice.actions

export function addToCart(
  product: ShopifyProduct,
  quantity: number,
  source: AddToCartSources,
  sellingPlanId?: string,
  isSubscription?: boolean,
): Thunk {
  return async (dispatch, getState) => {
    const title = product.title
    const variantId = product.variants[0]!.id
    const lineItem: ShopifyLineItem = {
      variantId,
      quantity,
      sellingPlanId: isSubscription ? sellingPlanId : undefined,
      isSubscription,
    }

    dispatch(slice.actions.addToCart(lineItem))
    persistCart(getState().shopify.cart)

    // don't track buy now button cart adds as these have their own mixpanel event
    if (source !== AddToCartSources.BuyNowButton) {
      mixpanelService.track('ShopifyAddToCart', {
        product: title,
        quantity,
        source,
      })
    }
  }
}

export function changeLineItemQuantity(variantId: string, quantity: number, title: string): Thunk {
  return async (dispatch, getState) => {
    if (quantity === 0) {
      dispatch(removeFromCart(variantId, title))
      return
    }

    const lineItem: ShopifyLineItem = { variantId, quantity }
    dispatch(slice.actions.changeLineItemQuantity(lineItem))
    persistCart(getState().shopify.cart)

    mixpanelService.track('ShopifyChangeLineItemQuantity', {
      product: title,
      quantity,
    })
  }
}

export function changeIsSubscription(variantId: string, isSubscription: boolean): Thunk {
  return async (dispatch, getState) => {
    const item = getState().shopify.cart[variantId]
    const lineItem: ShopifyLineItem = { ...item!, isSubscription }
    dispatch(slice.actions.changeIsSubscription(lineItem))
    persistCart(getState().shopify.cart)
  }
}

export function changeLineItemSellingPlan(
  variantId: string,
  sellingPlanId: string | undefined,
): Thunk {
  return async (dispatch, getState) => {
    dispatch(slice.actions.changeLineItemSellingPlan({ variantId, sellingPlanId }))
    persistCart(getState().shopify.cart)
  }
}

export function removeFromCart(variantId: string, title: string): Thunk {
  return async (dispatch, getState) => {
    dispatch(slice.actions.removeFromCart(variantId))
    persistCart(getState().shopify.cart)

    mixpanelService.track('ShopifyRemoveFromCart', {
      product: title,
    })
  }
}

export function buyNow(product: ShopifyProduct, quantity: number): Thunk {
  return async dispatch => {
    mixpanelService.trackClick('ShopifyBuySingleProduct', {
      product: product.title,
      quantity,
    })

    dispatch(addToCart(product, quantity, AddToCartSources.BuyNowButton))
    dispatch(checkout())
  }
}

export function clearCart(): Thunk {
  return dispatch => {
    sessionStorage.removeItem('cart')
    dispatch(slice.actions.clearCart())
  }
}

function setUsersRegion(state: ShopifyState): GeolocationCountry {
  const region = state.shippingLocation
  let country: GeolocationCountry

  if (region === ShippingLocation.EU) {
    country = GeolocationCountry.DE
  } else if (region === ShippingLocation.GB) {
    country = GeolocationCountry.GB
  } else if (region === ShippingLocation.SE) {
    country = GeolocationCountry.SE
  } else {
    country = GeolocationCountry.US
  }
  return country
}

function checkoutWithCreateCart(
  state: ShopifyState,
  lineItems: ShopifyLineItem[],
  geolocation: GeolocationCountry,
): Thunk {
  const utmParams = getAllUtms()
  return async () => {
    const distinctId = window.Cookiebot?.consent?.statistics
      ? mixpanelService.getDistinctId()
      : undefined
    const input: CreateCartInput = {
      buyerIdentity: {
        countryCode:
          !state.alteredRegion && geolocation !== GeolocationCountry.Unknown
            ? geolocation
            : setUsersRegion(state),
      },
      lines: lineItems.map(lineItem => ({
        merchandiseId: lineItem.variantId,
        quantity: lineItem.quantity,
        sellingPlanId: lineItem.sellingPlanId
          ? `gid://shopify/SellingPlan/${lineItem.sellingPlanId}`
          : null,
      })),
      attributes: _objectEntries(utmParams).map(([key, value]) => ({
        key,
        value,
      })),
    }
    if (distinctId) {
      input.attributes.push({ key: 'distinct_id', value: distinctId })
    }

    const res = await shopifyClient.request<CreateCartData>(cartCreateMutation, {
      variables: { input },
    })

    if (res.errors) {
      console.error(res.errors.message, res.errors.graphQLErrors)
      return
    }
    const { checkoutUrl } = res.data!.cartCreate.cart
    ncNavigate(checkoutUrl)
  }
}

/**
 * @deprecated 2025-04-01 We should use checkoutWithCreateCart instead,
 * which also supports subscriptions. However, currency formatting is
 * bugged with that function, so we need to fix that first and only
 * use checkoutWithCreateCart function for subscription purchases.
 */
function checkoutWithCreateCheckout(
  state: ShopifyState,
  lineItems: ShopifyLineItem[],
  geolocation: GeolocationCountry,
): Thunk {
  const utmParams = getAllUtms()
  const distinctId = mixpanelService.getDistinctId()
  return async () => {
    const input: CreateCheckoutInput = {
      buyerIdentity: {
        countryCode:
          !state.alteredRegion && geolocation !== GeolocationCountry.Unknown
            ? geolocation
            : setUsersRegion(state),
      },
      lineItems: lineItems.map(lineItem => ({
        variantId: lineItem.variantId,
        quantity: lineItem.quantity,
      })),
      customAttributes: _objectEntries(utmParams).map(([key, value]) => ({
        key,
        value,
      })),
    }
    if (distinctId) {
      input.customAttributes.push({ key: 'distinct_id', value: distinctId })
    }

    const res = await shopifyClient.request<CreateCheckoutData>(checkoutCreateMutation, {
      variables: { input },
    })

    if (res.errors) {
      console.error(res.errors.message, res.errors.graphQLErrors)
      return
    }

    const { webUrl } = res.data!.checkoutCreate.checkout
    ncNavigate(webUrl)
  }
}

export function checkout(): Thunk {
  return (_dispatch, getState) => {
    const state = getState()
    const products = state.shopify.cart
    const geolocation = state.geolocation.country

    const lineItems: ShopifyLineItem[] = Object.values(products)

    const hasItemWithSellPlan = lineItems.some(item => item.sellingPlanId)

    if (hasItemWithSellPlan) {
      _dispatch(checkoutWithCreateCart(state.shopify, lineItems, geolocation))
    } else {
      _dispatch(checkoutWithCreateCheckout(state.shopify, lineItems, geolocation))
    }
  }
}

export function setShippingLocationByCountry(country: GeolocationCountry): Thunk {
  return dispatch => {
    dispatch(slice.actions.setShippingLocationByCountry(country))
  }
}

export function setExpectedLocationByCountry(country: GeolocationCountry): Thunk {
  return dispatch => {
    dispatch(slice.actions.setExpectedLocationByCountry(country))
  }
}

export function setShippingLocation(location: any): Thunk {
  return dispatch => {
    dispatch(slice.actions.changeShippingLocation(location))
  }
}

export function setExpectedLocation(location?: ShippingLocation): Thunk {
  return dispatch => {
    dispatch(slice.actions.setExpectedLocation(location))
  }
}

export function setAlteredRegion(alteredRegion: boolean): Thunk {
  return dispatch => {
    dispatch(slice.actions.alteredRegion(alteredRegion))
  }
}

export function selectShopify({ shopify }: { shopify: ShopifyState }): ShopifyState {
  return shopify
}

export const selectShopifyShippingLocation = createSelector(
  [selectShopify],
  (shippingLocation): ShippingLocation | undefined => {
    return shippingLocation.shippingLocation
  },
)

export const selectExpectedLocation = createSelector(
  [selectShopify],
  (expectedLocation): ShippingLocation | undefined => {
    return expectedLocation.expectedLocation
  },
)

export const selectShopifyCurrency = createSelector(
  [selectShopify],
  (shippingCurrency): ShippingCurrency => {
    return shippingCurrency.currency
  },
)

export const selectMiniCartOpened = createSelector([selectShopify], (shopify): boolean => {
  return shopify.miniCartOpened
})

function persistCart(cart: ShopifyCart): void {
  sessionStorage.setItem('cart', JSON.stringify(cart))
}

export function sellingPlanToDurationStr(sellingplan: PersistedSellingPlan): string {
  return `Every ${sellingplan.delivery_interval_count} ${sellingplan.delivery_interval}${
    sellingplan.delivery_interval_count > 1 ? 's' : ''
  }`
}
