import { isServerSide } from '@naturalcycles/js-lib'
import { cookieService, Utms, utmService } from '@naturalcycles/shared'
import { sentry } from '@src/srv/sentry.service'

export function getAllUtms(): Utms {
  if (isServerSide() && process.env['GATSBY_ENV'] !== 'test') return {}
  try {
    const utms = utmService.refresh()
    return utms
  } catch (err) {
    console.log(cookieService.getCookie('utms'), err)
    sentry.captureException(new Error('Issue while refreshing utms'))
    return {}
  }
}
